<template>
  <div v-if="getModalBuyCourse.active" class="modalSale" data-testid="modal-sale">

    <div class="modalSale__poster">
      <div class="modalSale__poster--header">
        <div class="buttonClose">
          <span @click="closeAction"><IconSax name="close-circle" /></span>
        </div>
      </div>
      <div class="modalSale__poster--body">
        <div
          class="modalContent"
          v-if="getModalBuyCourse.data?.title && getModalBuyCourse.data.cover"
        >
          <div class="modalContent__poster">
            <CoverCard :cover="getModalBuyCourse.data?.cover ? getModalBuyCourse.data.cover : {}" />
          </div>
          <div class="modalContent__cta">
            <div class="modalContent__cta--title" v-if="getModalBuyCourse.data?.title">
              {{ getModalBuyCourse.data.title }}
            </div>
            <div
              class="modalContent__cta--description"
              v-if="getModalBuyCourse.data.description"
              v-html="getModalBuyCourse.data.description"
            ></div>
            <div
              class="modalContent__cta--description"
              v-else
            >
              Não há informações disponíveis
            </div>

            <div class="modalContent__cta--button" v-if="getModalBuyCourse.data?.button && getModalBuyCourse.data?.link">
              <ButtonPlay
                size="smm"
                type="normal"
                @click="openUrlAction(getModalBuyCourse.data.link)"
              >
                {{ getModalBuyCourse.data.button }}
              </ButtonPlay>
            </div>
          </div>
        </div>
      </div>
      <div class="modalSale__poster--footer">
        <slot name="footer" />
      </div>
    </div>
    <div class="modalSale__thumb"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import CoverCard from "@/components/common/cards/cover/Cover.vue";

export default {
   computed: {
    ...mapGetters({
      getModalBuyCourse: "home/getModalBuyCourse"
    })
  },
  components: {
    CoverCard,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    closeAction() {
      this.$emit("close");
    },
    openUrlAction(url) {
      return window.open(url, '_blank')
    },
  },
};
</script>

<style lang="scss" scoped>
.modalSale {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 10001;
  justify-content: center;
  align-items: center;
  background: #00000070;
  &__poster {
    border-radius: calc(var(--default-radius) * 2);
    box-shadow: var(--shadow-c);
    background: var(--modal-background);
    width: 648px;
    height: 400px;
    flex-shrink: 0;
    padding: var(--spacing-24);

    @media screen and (max-width: 768px) {
      width: 90%;
      height: auto;
      max-width: 340px;
      max-height: 520px;
      overflow-y: auto;
    }

    &--header {
      display: flex;
      justify-content: flex-end;
      > .buttonClose {
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
        ::v-deep .svgCss {
          svg {
            path {
              fill: var(--modal-close-color);
            }
          }
        }
      }
    }
    &--body {
      padding: var(--spacing-12) var(--spacing-48);
      height: 100%;
      display: flex;
      align-items: center;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        padding: var(--spacing-24)  0;
      }

      ::v-deep .modalContent {
        display: flex;
        gap: var(--spacing-40);
        height: 100%;

        @media screen and (max-width: 768px) {
          flex-direction: column;
          width: 100%;
          height: auto;
        }

        &__poster {
          width: 177px;

          @media screen and (max-width: 768px) {
            display: flex;
            justify-content: center;
            width: 100%;
          }

          .playImage__box {
            min-width: 177px;
            min-height: 238px;
            border-radius: var(--default-radius);
          }
        }
        &__cta {
          @include flex-column;
          gap: var(--spacing-8);
          justify-content: center;
          align-items: flex-start;

          @media screen and (max-width: 768px) {
            gap: var(--spacing-20);
          }

          &--title {
            @extend .body-semibold-20;
            color: var(--fontcolor);
          }
          &--description {
            @extend .body-regular-14;
            color: var(--fontcolor);
            min-height: 80px;
            max-height: 15rem;
            overflow: auto;
          }
          &--button {
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
